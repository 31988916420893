<template>
  <div>
    <Breadcrumbs title="Teacher Summary Report" main="Reports"/>
    <div class="container-fluid">
      <b-row>
        <b-col cols="12">
          <div class="card">
            <div class="card-body">

              <b-row>
                <b-col cols="12" md="4">
                  <b-form-group label="Lecturer">
                    <b-form-select @change="fetchClasses" v-model="formSearch.teacher_id" :options="lecturers" value-field="id" text-field="text" class="btn-square border"></b-form-select>
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="4">
                  <b-form-group label="Institute">
                    <b-form-select @change="fetchClasses" v-model="formSearch.institute_id" :options="institutes" value-field="id" text-field="text" class="btn-square border"></b-form-select>
                  </b-form-group>
                </b-col>
     
                <b-col cols="12" md="4">
                  <b-form-group label="Payment Month">
                    <b-form-input  v-model="formSearch.payment_month" type="month" class="btn-square border" placeholder="All Months"></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12" class="text-center">
                  <b-button @click="generateReport" :disabled="!formSearch.teacher_id || loading" size="sm" class="btn btn-square text-uppercase btn-primary m-2">Generate</b-button>
                  <b-button @click="printReport('print')" :disabled="!totalRows || loading" size="sm" class="btn btn-square text-uppercase btn-success m-2">Print</b-button>
                </b-col>
              </b-row>

            </div>
          </div>

          <div class="card">
            <div class="card-body" id="print">
              <div class="">

                <div class="text-center">
                  <h2 class="card-title">Teacher Summary Report</h2>
                </div>

                <b-row>
                  <b-col cols="4" md="3" lg="2" class="text-left">Teacher</b-col>
                  <b-col cols="8" md="9" class="text-left">: {{ lecturers.filter(record => record.id===formSearch.teacher_id).map(record => record.text)[0] }}</b-col>
                </b-row>
                <b-row>
                  <b-col cols="4" md="3" lg="2" class="text-left">Institute</b-col>
                  <b-col cols="8" md="9" class="text-left">: {{ institutes.filter(record => record.id===formSearch.institute_id).map(record => record.text)[0] }}</b-col>
                </b-row>
                <!-- <b-row>
                  <b-col cols="4" md="3" lg="2" class="text-left">Class</b-col>
                  <b-col cols="8" md="9" class="text-left">: {{ class_details.filter(record => record.id===formSearch.subject_id).map(record => record.text)[0] }}</b-col>
                </b-row> -->
                <b-row>
                  <b-col cols="4" md="3" lg="2" class="text-left">Payment Month</b-col>
                  <b-col cols="8" md="9" class="text-left">: {{ formSearch.payment_month || "All Months" }}</b-col>
                </b-row>
                <b-row>
                  <b-col cols="4" md="3" lg="2" class="text-left">Record Count</b-col>
                  <b-col cols="8" md="9" class="text-left">: {{ totalRows }}</b-col>
                </b-row>

                <div id="details_table" class="table-responsive datatable-vue" v-show="!loading">
                  <b-table
                      ref="table"
                      show-empty
                      :items="tableRecords"
                      :fields="tableFields"
                      :filter="filter"
                      :current-page="currentPage"
                      :per-page="perPage"
                  >
                    <template #cell(index)="row">
                      {{ (1+row.index) }}
                    </template>  
                    <template #cell(class_name)="row">{{ row.item.class_name }}</template>
                    <template #cell(card)="row">{{ row.item.card || '' }}</template>
                    <template #cell(class_fee)="row">{{ row.item.class_fee || '' }}</template>
                    <template #cell(amount_sum)="row">{{ row.item.amount_sum || '' }}</template>
                    <template #cell(institute_rent)="row">{{ row.item.institute_rent || '' }}</template>
                    <template #cell(total)="row">{{ row.item.total || '' }}</template>
                  </b-table>
                </div>

              <div :hidden="!totalRows || loading">
                <b-row>
                  <b-col cols="4" md="3" lg="2" class="text-left">Final Sub Total</b-col>
                  <b-col cols="8" md="9" class="text-left">: {{finalData.sub_total}}</b-col>
                </b-row>
                 <b-row>
                  <b-col cols="4" md="3" lg="2" class="text-left">Total Institute Rent</b-col>
                  <b-col cols="8" md="9" class="text-left">: {{finalData.total_institute_rent}}</b-col>
                </b-row>
                 <b-row>
                  <b-col cols="4" md="3" lg="2" class="text-left">Total Amount</b-col>
                  <b-col cols="8" md="9" class="text-left">: {{finalData.total_amount}}</b-col>
                </b-row>
              </div>
              <div class="mt-3" :hidden="!totalRows || loading">
                <b-row>
                  <b-col cols="3" md="3" lg="2" class="text-left">Payment Method</b-col>
                  <b-col cols="3" md="3" class="text-left">Sub Total</b-col>
                  <b-col cols="3" md="3" class="text-left">Rental</b-col>
                  <b-col cols="3" md="3" class="text-left">Total</b-col>
                </b-row>
                
              </div>

              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
  import vuejsDatepicker from 'vuejs-datepicker';
  import {Printd} from "printd";
  import {mapState} from "vuex";
  import {checkUserPermissions} from "@/plugins/functions";
  import API from '@/services/api'
  import CRUD_API from "@/services/crud";
  import moment from "moment";
  export default {
    name: "teacher_summary_report",
    computed: {
      ...mapState({ user: state => JSON.parse(state.auth.user) }),
    },
    watch: {
      'formSearch.year': function (value) {
        if(null===value) {
          this.formSearch.year = null
        } else this.formSearch.year = moment(value).format('y')
        this.fetchClasses()
      },
      'formSearch.is_paid': function (value) {
        if(value === null) {
          this.formSearch.payment_month = null
        }
      }
    },
    components: {
      'vuejs-datepicker': vuejsDatepicker
    },
    data() {
      return {
        //Search Details
        api_base: 'api/teacher-summery-reports',
        filter: '',
        totalRows: 0,
        finalData:0,
        perPage: 100,
        lastPage: 1,
        currentPage: 1,
        tableRecords: [],
        loading: false,
        // Report Details
        institutes: [],
        lecturers: [],
        class_details: [],
        
        formSearch: {
          institute_id: null,
          teacher_id: null,
          // subject_id: null,
          payment_month: null,
        },
        // count=formSearch->arry(count),
        tableFields: [
          { key: 'index', label: '#', sortable: false, },
          { key: 'class_name', label: 'Class', sortable: false, class: 'text-left' },
          { key: 'card', label: 'Card', sortable: false, class: 'text-left' },
          { key: 'class_fee', label: 'Class Fee', sortable: false, class: 'text-left' },
          { key: 'amount_sum', label: 'Sub Total', sortable: false, },
          { key: 'institute_rent', label: 'Rent', sortable: false, },
          { key: 'total', label: 'Total', sortable: false, class: 'text-left' },
        ],
      }
    },
    mounted() {
      this.fetchInstitutes()
      this.fetchTeachers()
    },
    methods: {
      checkUserPermissions,
      clearResult(){
        this.tableRecords = []
      },
      async fetchInstitutes() {
        this.institutes = []
        await API.select('institutes', {
          status: 0
        }).then((result) => {
          if(result.data.data.length) {
            this.institutes = result.data.data
          }
        }).catch((error) => {
          console.log(error)
        }).finally(() => {
          this.institutes.unshift({
            id: null, text: "All Institutes"
          })
          this.fetchClasses()
        })
      },
      async fetchTeachers() {
        this.clearResult()
        this.lecturers = []
        await API.select('teachers', {
          institute_id: this.formSearch.institute_id
        }).then((result) => {
          this.lecturers = result.data.data
        }).catch((error) => {
          console.log(error)
        }).finally(() => {
          this.lecturers.unshift({
            id: null, text: "All Teachers"
          })
          this.fetchClasses()
        })
      },
      async fetchClasses() {
        this.clearResult()
        this.class_details = []
        // this.formSearch.subject_id = null
        await API.select('classes', {
          institute_id: this.formSearch.institute_id,
          teacher_id: this.formSearch.teacher_id,
          year: this.formSearch.year
        }).then(result => {
          this.class_details = result.data.data
        }).catch(error => {
          console.log(error)
        }).finally(() => {
          this.class_details.unshift({
            id: null, text: "All Classes"
          })
        })
      },
      printReport(el) {
        const d = new Printd()
        d.print(document.getElementById(el), [`
      * { font-family: serif; font-size: 15px; },
          @page { text-align:center; }
          div.row > div { display: inline-block;}
          div.row { display: block; }
          #details_table { padding-top: 50px;  padding-bottom: 50px;}
          h2 { font-size: 20px; text-align:center; }
          th, td {padding: 10px;}
       `])
      },
      
      async generateReport() {
        // this.tableRecords = []
        // this.currentPage = 0
        // this.report_total = 0
        // this.totalRows = 0
        // this.institute_share = 0
        // this.teacher_share = 0
        // this.discount_total = 0
        // this.loading = true

        if (null !== this.formSearch.is_paid && null===this.formSearch.payment_month) {
          this.$toasted.error("Payment month must be selected when filtering with payment status")
          this.loading = false
        }else {
          await CRUD_API.index(this.api_base, {
            params: {
              institute_id: this.formSearch.institute_id,
              teacher_id: this.formSearch.teacher_id,
              // subject_id: this.formSearch.subject_id,
              payment_month: this.formSearch.payment_month,
            }
          }).then(result => {
            console.log(result.data.data.table_data.rows)
            this.currentPage = 1
            this.tableRecords = result.data.data.table_data.rows
            this.finalData = result.data.data.table_data
            this.totalRows = result.data.data.table_data.rows.length
            this.perPage = this.totalRows
           
            
          }).catch(error => {
            // console.log(error)
            this.$toasted.error(error.message)
          }).finally(() => this.loading = false)
        }
      },
    }
  }
</script>

<style scoped>

</style>
